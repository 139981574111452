<template>
  <div id="clue-panel" v-cloak v-loading.fullscreen.lock="loading">
    <div
      class="con"
      :style="{
        width: panelOption.length * 560 + (panelOption.length - 1) * 10 + 'px',
      }"
    >
      <div class="flex-between flex-a-center panel-box bg_white">
        <h1>{{ $t("layout.kanbanLeads") }}</h1>
        <div v-show="!isClickEdit" class="inner-panel">
          <el-button
            size="mini"
            class="white-btn mr40"
            @click="isClickEdit = true"
            v-if="rightShow('CrmContact@savePanelUserGroup')"
          >
            <i class="el-icon-edit"></i>
            {{ $t("cluePanel.edit") }}
          </el-button>
          <el-button size="mini" class="white-btn mr40" @click="jumpClue">
            <i class="el-icon-plus"></i>
            {{ $t("cluePanel.new") }}
          </el-button>
        </div>
        <el-tooltip
          class="item inner-panel"
          effect="dark"
          :content="$t('cluePanel.addStatus')"
          placement="left-start"
        >
          <i
            class="el-icon-circle-plus-outline add-task-icon hand"
            @click="addPanel"
            v-show="isClickEdit"
          ></i>
        </el-tooltip>
      </div>
      <div
        class="main-panel"
        :style="{
          width:
            panelOption.length * 560 + (panelOption.length - 1) * 10 + 'px',
        }"
      >
        <draggable
          v-model="panelOption"
          group="panel"
          @start="drag = true"
          :move="draggableHead"
          class="draggable-main-panel"
          v-bind="headDragOptions"
          handle=".move"
        >
          <div
            class="panel-w"
            v-for="(panelItem, panelIndex) in panelOption"
            :key="panelIndex"
          >
            <!-- 标题 -->
            <div
              :class="[
                { move: isClickEdit },
                'flex-center',
                'panel-item',
                'bg_white',
                'flex-between',
              ]"
            >
              <div class="item"></div>
              <div class="flex-a-center">
                <span class="panel-index">{{ panelIndex + 1 }}</span>
                <span
                  :class="{ hand: isClickEdit && titIsEdit[panelIndex][0] }"
                  v-show="!titIsEdit[panelIndex][0]"
                >
                  <span
                    class="panel-name"
                    @click="showInputFn(panelIndex, 0, true)"
                    >{{ panelItem.name }}</span
                  >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('cluePanel.text')"
                    placement="top-end"
                    v-show="isClickEdit"
                  >
                    <i class="el-icon-info"></i>
                  </el-tooltip>
                </span>
                <el-input
                  v-model="panelItem.name"
                  :placeholder="$t('cluePanel.text1')"
                  :ref="'input' + panelIndex + '0'"
                  class="w150 panel-name"
                  size="mini"
                  v-show="titIsEdit[panelIndex][0]"
                  maxlength="20"
                  show-word-limit
                  @blur="showInputFn(panelIndex, 0, false)"
                ></el-input>

                <!-- <span class="panel-desc" @dblclick="showInputFn(panelIndex,1,true)" v-show="!titIsEdit[panelIndex][1]">{{ panelItem.desc }}</span>
                            <el-input v-model="panelItem.desc" placeholder="请输入" class="w150" size="mini" 
                            v-show="titIsEdit[panelIndex][1]" :ref="'input'+ panelIndex + '1'"
                             @blur="showInputFn(panelIndex,1,false)"></el-input> -->
              </div>
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('cluePanel.delStatus')"
                placement="left-start"
              >
                <i
                  class="el-icon-delete delete-icon"
                  v-show="isClickEdit"
                  @click="delPanel(panelIndex)"
                ></i>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('cluePanel.addLead')"
                placement="bottom-start"
              >
                <i
                  class="iconfont icon-chuangjianrenwu edit-panel-icon"
                  v-show="!isClickEdit"
                  @click="addNewTask(panelIndex)"
                ></i>
              </el-tooltip>
            </div>
            <!-- 第一个展示新增 -->
            <div class="add-new-box bg_white" v-if="panelItem.isClickAdd">
              <template v-if="newForm[panelIndex]">
                <div class="new-form_item flex-a-center">
                  <span class="panel-form_tit"
                    ><span class="red">*</span>
                    {{ $t("cluePanel.leadName") }}：</span
                  >
                  <el-input
                    v-model="newForm[panelIndex].clue_name"
                    :placeholder="$t('cluePanel.text2')"
                    class="w268"
                    size="mini"
                  ></el-input>
                </div>
                <div class="new-form_item flex-a-center">
                  <span class="panel-form_tit"
                    >{{ $t("email.contact") }}：</span
                  >
                  <el-input
                    v-model="newForm[panelIndex].person"
                    :placeholder="$t('cluePanel.text3')"
                    class="w268"
                    size="mini"
                  ></el-input>
                </div>
                <div class="new-form_item flex-a-center">
                  <span class="panel-form_tit"
                    >{{ $t("clueDetails.contact") }}：</span
                  >
                  <el-input
                    v-model="newForm[panelIndex].phone"
                    :placeholder="$t('cluePanel.text4')"
                    class="w268"
                    size="mini"
                  ></el-input>
                </div>
                <div class="new-form_item flex-a-center">
                  <span class="panel-form_tit"
                    >{{ $t("contactList.companyName") }}：</span
                  >
                  <el-input
                    v-model="newForm[panelIndex].bus_name"
                    :placeholder="$t('cluePanel.text5')"
                    class="w268"
                    size="mini"
                  ></el-input>
                </div>
                <div class="new-form_item flex-a-center">
                  <span class="panel-form_tit"
                    >{{ $t("clueDetails.companyEmail") }}：</span
                  >
                  <el-input
                    v-model="newForm[panelIndex].email"
                    :placeholder="$t('cluePanel.text6')"
                    class="w268"
                    size="mini"
                  ></el-input>
                </div>
                <div class="flex-j-end">
                  <el-button
                    size="mini"
                    type="primary"
                    @click="submitNew(panelIndex)"
                    >{{ $t("submit") }}</el-button
                  >
                </div>
              </template>
              <i
                class="el-icon-close close-icon hand"
                @click="hidePanel(panelIndex)"
              ></i>
            </div>
            <!-- 单个面板 -->
            <draggable
              v-model="panelItem.clue_list"
              group="panel"
              class="draggable-item"
              v-bind="dragOptions"
              :data-status_id="panelItem.id"
              @end="draggableClue"
            >
              <template v-if="panelItem.clue_list.length">
                <div
                  :class="[
                    { 'disabled-edit': isClickEdit },
                    'panel-item-box',
                    'bg_white',
                  ]"
                  v-for="(pItem, pIndex) in panelItem.clue_list"
                  :key="pIndex"
                  :data-id="pItem.id"
                  :data-status_id="panelItem.id"
                  @click="clickPanelItem(panelIndex, pIndex)"
                >
                  <div class="flex-between name-time">
                    <h1
                      class="hand clue-name_hand"
                      @click.stop="jumpDetail(pItem.id)"
                    >
                      {{ pItem.clue_name }}
                    </h1>
                    <div>
                      <span
                        class="time hand"
                        @click.stop="jumpDetail(pItem.id)"
                        >{{ pItem.create_time }}</span
                      >
                      <el-checkbox v-model="pItem.isChecked"> </el-checkbox>
                    </div>
                  </div>
                  <div class="panel-form_item">
                    <span class="panel-form_tit"
                      >{{ $t("cluePanel.mainContact") }}：</span
                    >
                    <span
                      v-if="
                        pItem.contact_list !== undefined &&
                        pItem.contact_list[0].name
                      "
                      >{{ pItem.contact_list[0].name }}</span
                    >
                    <span v-else>{{ $t("projectInfo.no") }}</span>
                  </div>
                  <div class="panel-form_item">
                    <span class="panel-form_tit"
                      >{{ $t("clueDetails.contact") }}：</span
                    >
                    <span
                      v-if="
                        pItem.contact_list !== undefined &&
                        pItem.contact_list[0].mobile
                      "
                    >
                      <template v-if="pItem.contact_list[0].areaCode">
                        +{{ pItem.contact_list[0].areaCode }}
                      </template>
                      {{ pItem.contact_list[0].mobile }}
                    </span>
                    <span v-else>{{ $t("projectInfo.no") }}</span>
                  </div>
                  <div class="panel-form_item">
                    <span class="panel-form_tit"
                      >{{ $t("contactList.companyName") }}：</span
                    >
                    <span v-if="pItem.company">{{ pItem.company }}</span>
                    <span v-else>{{ $t("projectInfo.no") }}</span>
                  </div>
                  <div class="panel-form_item">
                    <span class="panel-form_tit"
                      >{{ $t("clueDetails.companyEmail") }}：</span
                    >
                    <span v-if="pItem.company">{{ pItem.email }}</span>
                    <span v-else>{{ $t("projectInfo.no") }}</span>
                  </div>
                  <div class="plan-detail">
                    <div
                      class="plan-item flex-a-center"
                      v-for="(planItem, index) in pItem.plan_list"
                      :key="index"
                    >
                      <i
                        :class="[getIconByType(planItem.plan_type), 'iconfont']"
                      ></i>
                      <span>
                        {{ planItem.want_create_time }}
                      </span>
                      <el-tag
                        v-if="planItem.is_task && planItem.is_task == 1"
                        @click="jumpTask(planItem.id)"
                        >{{ planItem.task_name }}</el-tag
                      >
                      <span
                        class="plan_desc ellipsis ml5"
                        :title="planItem.plan_desc"
                      >
                        {{ planItem.contact_name }}
                        {{ planItem.task_title }}
                        {{ planItem.plan_desc }}
                      </span>
                    </div>
                  </div>
                  <div class="panel-form_item mt30 flex-a-center">
                    <span class="panel-form_tit"
                      >{{ $t("cluePanel.text7") }}：</span
                    >
                    <span
                      v-if="pItem.charge_list && pItem.charge_list.length === 0"
                      class="no-user"
                      >{{ $t("projectInfo.no") }}</span
                    >
                    <div v-else class="user-box">
                      <span
                        v-for="(userItem, index) in pItem.charge_list"
                        class="mr10 flex-a-center"
                        :key="index"
                      >
                        <img :src="userItem.photo" class="user-photo" />
                        <span
                          class="user-name ellipsis"
                          :title="userItem.name"
                          >{{ userItem.name }}</span
                        >
                      </span>
                    </div>
                  </div>
                  <el-popover
                    placement="bottom-end"
                    width="213"
                    trigger="hover"
                  >
                    <ul class="task-ul">
                      <li
                        v-for="(item, index) in taskOption"
                        @click="addTask(item, panelIndex, pIndex)"
                        :key="index"
                      >
                        <i :class="['iconfont', item.icon, 'task-icon']"></i>
                        {{ item.name }}
                      </li>
                    </ul>
                    <el-button
                      slot="reference"
                      size="mini"
                      type="warning"
                      class="add-btn"
                      >{{ $t("clueDetails.newPlan") }}</el-button
                    >
                  </el-popover>
                  <div class="popover-option bg_white">
                    <span
                      class="popover-item"
                      @click.stop="editClue(pItem.id)"
                      >{{ $t("edit") }}</span
                    >
                    <span
                      class="popover-item"
                      @click.stop="
                        singleAddAssign(pItem.id, panelIndex, pIndex)
                      "
                      >{{ $t("taskInfo.assign") }}</span
                    >
                    <span
                      class="popover-item"
                      @click.stop="abandonTask(true, pItem.id)"
                      >{{ $t("clueList.giveUp") }}</span
                    >
                    <span
                      class="popover-item"
                      @click.stop="transformCus(pItem.id)"
                      >{{ $t("cluePanel.transformToClient") }}</span
                    >
                    <span
                      class="popover-item"
                      @click.stop="delClue(true, pItem.id)"
                      >{{ $t("delete") }}</span
                    >
                    <div class="singleStatus-span popover-item">
                      <span class="yellow-text">{{
                        $t("cluePanel.changeStatus")
                      }}</span>
                      <ul class="singleStatus-ul bg_white">
                        <li
                          v-for="(item, index) in statusOptions"
                          @click.stop="
                            changeStatus(item.id, panelIndex, pIndex)
                          "
                          :key="index"
                        >
                          {{ item.name }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
        </draggable>
      </div>
      <!-- 操作 -->
      <div class="handel-box">
        <template
          v-if="isClickEdit && rightShow('CrmContact@savePanelUserGroup')"
        >
          <el-button size="mini" class="mr40" @click="cancelEdit">{{
            $t("cancel")
          }}</el-button>
          <el-button size="mini" type="warning" @click="saveEdit">{{
            $t("save")
          }}</el-button>
        </template>
        <template v-else>
          <span class="mr10"
            >{{ $t("taskManagement.selected") }}{{ checkNum }}/ {{ panelNum }}
            {{ $t("CrmCus.unit") }}</span
          >
          <el-select
            v-model="statusValue"
            :placeholder="$t('cluePanel.changeStatus')"
            class="mr40"
            size="mini"
          >
            <el-option
              v-for="statusItem in statusOptions"
              :key="statusItem.id"
              :label="statusItem.name"
              :value="statusItem.id"
            >
            </el-option>
          </el-select>
          <el-button
            size="mini"
            type="warning"
            class="mr40"
            @click="batchChangeStatus"
            v-show="statusValue !== ''"
            >{{ $t("taskManagement.apply") }}</el-button
          >
          <el-button
            size="mini"
            type="primary"
            class="mr40"
            @click="addAssign"
            >{{ $t("clueList.newAssign") }}</el-button
          >
          <el-button
            size="mini"
            type="danger"
            class="mr40"
            @click="abandonTask(false)"
            >{{ $t("clueList.giveUp") }}</el-button
          >
          <el-button size="mini" type="danger" @click="delClue(false)">{{
            $t("delete")
          }}</el-button>
        </template>
      </div>
    </div>
    <!-- 指派任务弹窗 -->
    <el-dialog
      :title="userDialogTit"
      :visible.sync="dialogVisible"
      center
      v-move
      width="840"
    >
      <div class="user-dialog">
        <template>
          <div v-for="(personItem, personIndex) in personOp" :key="personIndex">
            <el-checkbox
              :indeterminate="isIndeterminateArr[personIndex]"
              v-model="checkAllArr[personIndex]"
              @change="handleCheckAllChange($event, personIndex)"
              class="department-tit"
            >
              <h2 class="group_name">
                {{ personItem.label }}
                <span class="user-num">
                  ({{ personItem.children.length }})
                </span>
              </h2>
            </el-checkbox>

            <el-checkbox-group
              v-model="checkValArr[personIndex]"
              @change="handleCheckedChange($event, personIndex)"
              class="mb20"
            >
              <el-checkbox
                v-for="mItem in personItem.children"
                :label="mItem.value"
                :key="mItem.value"
                >{{ mItem.label }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="dialogVisible = false"
          size="mini"
          class="cancel-btn mr80"
          >{{ $t("cancel") }}</el-button
        >
        <el-button type="warning" size="mini" @click="submitUser">{{
          $t("confirm")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 添加任务 弹窗-->
    <el-dialog
      :title="$t('clueDetails.newPlan')"
      :visible.sync="addTaskDialogVisible"
      center
      v-move
      width="650px"
    >
      <div class="dialog-task_box">
        <div class="mb10 flex-a-center">
          <span class="tit">
            <span class="red">*</span>{{ $t("clueList.planType") }}：
          </span>
          <el-select
            v-model="dialogType"
            :placeholder="$t('cluePanel.text8')"
            size="mini"
            class="w200"
          >
            <el-option
              v-for="item in taskOption.filter((item) => item.val != 4)"
              :key="item.val"
              :label="item.name"
              :value="item.val"
            >
            </el-option>
          </el-select>
        </div>
        <div class="mb10 flex-a-center">
          <span class="tit">
            <span class="red">*</span>
            {{ $t("email.contact") }}：
          </span>
          <el-select
            v-model="taskPerson"
            :placeholder="$t('cluePanel.text9')"
            size="mini"
            class="w200"
          >
            <el-option
              v-for="item in contactList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="mb10 flex-a-center">
          <span class="tit">
            <span class="red">*</span>
            {{ $t("taskInfo.estStartTime") }}：
          </span>
          <el-date-picker
            class="w200"
            size="mini"
            format="yyyy-MM-dd HH:mm"
            v-model="taskTime"
            type="datetime"
            value-format="timestamp"
            :placeholder="$t('cluePanel.text10')"
          >
          </el-date-picker>
        </div>
        <div class="mb10 flex-a-start">
          <span class="tit">{{ $t("cluePanel.text11") }}：</span>
          <el-input
            v-model="taskContent"
            size="mini"
            class="w500"
            type="textarea"
            :rows="5"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addTaskDialogVisible = false" size="mini">{{
          $t("cancel")
        }}</el-button>
        <el-button type="primary" @click="submitTask" size="mini">{{
          $t("confirm")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { getDateTime } from "@/utils/common";
import { toMessage } from "@/utils/message";
import "@/plugins/move";
export default {
  components: {
    draggable,
  },
  name: "CluePanel",
  data() {
    return {
      loading: false,
      panelOption: [],
      newForm: [],
      statusOptions: [],
      statusValue: "",
      isClickEdit: false, // 标致是否点击编辑面板按钮
      titIsEdit: [
        [false, false],
        [false, false],
        [false, false],
      ],
      // 添加任务选项
      taskOption: [
        {
          name: this.$t("cluePanel.call"),
          val: "0",
          icon: "icon-dianhua",
        },
        {
          name: this.$t("clueList.email"),
          val: "1",
          icon: "icon-xinxiang",
        },
        {
          name: this.$t("ContactEdit.visit"),
          val: "2",
          icon: "icon-zoufang",
        },
        {
          name: this.$t("ContactEdit.meeting"),
          val: "3",
          icon: "icon-huiyi",
        },
        {
          name: this.$t("ContactInfo.newTask"),
          val: "4",
          icon: "icon-chuangjianrenwu1",
        },
      ],
      personOp: [
        {
          group_name: "人事部",
          children: [
            {
              name: "人事1",
              id: 1,
            },
            {
              name: "人事2",
              id: 2,
            },
          ],
        },
        {
          group_name: "技术部",
          children: [
            {
              name: "技术1",
              id: 4,
            },
            {
              name: "技术2",
              id: 5,
            },
            {
              name: "技术3",
              id: 6,
            },
            {
              name: "技术4",
              id: 7,
            },
          ],
        },
      ],
      dialogVisible: false,
      checkValArr: [[], []],
      checkAllArr: [],
      isIndeterminateArr: [],
      addTaskDialogVisible: false,
      taskPerson: "",
      taskTime: "",
      taskContent: "",
      taskType: "",
      clue_id: "",
      contactList: [],
      singleId: "",
      isMultiple: false,
      dialogType: "",
      userDialogTit: "",
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    headDragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled:
          !this.rightShow("CrmContact@savePanelUserGroup") || !this.isClickEdit
            ? true
            : false,
        ghostClass: "ghost",
      };
    },
    // 选中看板的个数
    checkNum() {
      let num = 0;
      this.panelOption.forEach((element) => {
        if (element.clue_list) {
          element.clue_list.forEach((item) => {
            if (item.isChecked) {
              num += 1;
            }
          });
        }
      });
      return num;
    },
    // 所有看板的个数
    panelNum() {
      let num = 0;
      this.panelOption.forEach((element) => {
        if (element.clue_list) {
          element.clue_list.forEach(() => {
            num += 1;
          });
        }
      });
      return num;
    },
  },
  inject: ["rightShow"],
  methods: {
    //点击单个面板的空白区域
    clickPanelItem(pindex, index) {
      this.panelOption[pindex]["clue_list"][index]["isChecked"] = !this
        .panelOption[pindex]["clue_list"][index]["isChecked"];
    },
    getIconByType(type) {
      let icon = "";
      this.taskOption.forEach((item) => {
        if (item.val == type) {
          icon = item.icon;
        }
      });
      return icon;
    },
    // 编辑标题
    showInputFn(index, nameIndex, isShowInput) {
      if (isShowInput) {
        if (this.isClickEdit) {
          let tem = [false, false];
          tem[nameIndex] = true;
          setTimeout(() => {
            this.$refs["input" + index + nameIndex][0].focus();
          }, 0);
          this.titIsEdit.splice(index, 1, tem);
        }
      } else {
        this.titIsEdit.splice(index, 1, [false, false]);
      }
    },
    // 取消编辑
    cancelEdit() {
      this.isClickEdit = false;
      this.getInfo();
    },
    // 保存
    saveEdit() {
      this.isClickEdit = false;
      this.loading = true;
      let ajaxData = [];
      this.panelOption.forEach((item) => {
        let tem = {
          name: item.name,
          desc: item.desc,
        };
        if (item.id) {
          tem.id = item.id;
        }
        ajaxData.push(tem);
      });
      this.axios
        .post("/home/CrmContact/savePanelUserGroup", {
          group_list: ajaxData,
        })
        .then((res) => res.data)
        .then((res) => {
          this.loading = false;
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            this.getInfo();
            return;
          }
          this.getInfo();
        });
    },
    // 删除单个看板
    delPanel(index) {
      this.$confirm(this.$t("cluePanel.text12"), this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          if (this.panelOption[index].id == undefined) {
            this.titIsEdit.splice(index, 1, [false, false]);
            this.panelOption.splice(index, 1);
            return;
          }
          this.loading = true;
          this.axios
            .post("/home/CrmContact/judgeClueStatus", {
              status_id: this.panelOption[index].id,
            })
            .then((res) => res.data)
            .then((res) => {
              this.loading = false;
              if (res.status !== 1) {
                this.$message(toMessage("error", res.msg));
                return;
              }
              this.titIsEdit.splice(index, 1, [false, false]);
              this.panelOption.splice(index, 1);
            });
        })
        .catch(() => {});
    },
    // 新增看板
    addPanel() {
      if (this.panelOption.length >= 5) {
        this.$message(toMessage("error", this.$t("cluePanel.error")));
        return;
      }
      let temObj = {
        name: this.$t("cluePanel.changeName"),
        desc: this.$t("projectInfo.no"),
        isClickAdd: false,
        clue_list: [],
      };
      this.addNewForm();
      this.titIsEdit.push([false, false]);
      this.panelOption.push(temObj);
    },
    // 改变单个状态
    changeStatus(val, index, pIndex) {
      this.panelOption[index].clue_list[pIndex].status = val;
      let idStr = this.panelOption[index].clue_list[pIndex].id;
      this.axios
        .post("/home/CrmContact/cluePlanOperation", {
          ids: [idStr],
          status: val,
          operation: 0,
          source_type: 0,
        })
        .then((res) => res.data)
        .then((res) => {
          this.loading = false;
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          this.getInfo();
        });
    },
    // 放弃
    abandonTask(isSingle, id) {
      if (!isSingle) {
        // 批量放弃
        let idArr = [];
        this.panelOption.forEach((element) => {
          if (element.clue_list) {
            element.clue_list.forEach((item) => {
              if (item.isChecked) {
                idArr.push(item.id);
              }
            });
          }
        });
        if (idArr.length === 0) {
          this.$message(toMessage("error", this.$t("cluePanel.error1")));
          return;
        }
      }
      this.$confirm(this.$t("cluePanel.text13"), this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      }).then(() => {
        this.loading = true;
        let idStr = [];
        if (isSingle) {
          // 单个放弃
          idStr = [id];
        } else {
          // 批量放弃
          this.panelOption.forEach((element) => {
            if (element.clue_list) {
              element.clue_list.forEach((item) => {
                if (item.isChecked) {
                  idStr.push(item.id);
                }
              });
            }
          });
        }
        this.axios("/home/CrmContact/cluePlanOperation", {
          ids: idStr,
          operation: 2,
          source_type: isSingle ? 0 : 1,
        })
          .then((res) => res.data)
          .then((res) => {
            this.loading = false;
            if (res.status !== 1) {
              this.$message(toMessage("error", res.msg));
              return;
            }
            this.getInfo();
          })
          .catch(() => {});
      });
    },
    // 批量改变状态
    batchChangeStatus() {
      let idArr = [];
      this.panelOption.forEach((element) => {
        if (element.clue_list) {
          element.clue_list.forEach((item) => {
            if (item.isChecked) {
              idArr.push(item.id);
            }
          });
        }
      });
      if (idArr.length === 0) {
        this.$message(toMessage("error", this.$t("cluePanel.error2")));
        this.statusValue = "";
        return;
      }
      this.loading = true;
      this.axios
        .post("/home/CrmContact/cluePlanOperation", {
          ids: idArr,
          status: this.statusValue + "",
          source_type: 1,
          operation: 0,
        })
        .then((res) => res.data)
        .then((res) => {
          this.loading = false;
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            this.statusValue = "";
            return;
          }
          this.statusValue = "";
          this.getInfo();
        });
    },
    // 点击编辑icon 新增
    addNewTask(panelIndex) {
      this.$set(this.panelOption[panelIndex], "isClickAdd", true);
    },
    // 增加newForm
    addNewForm() {
      this.newForm.push({
        clue_name: "",
        person: "",
        phone: "",
        bus_name: "",
        email: "",
        create_time: getDateTime().substr(0, 16),
      });
    },
    // 新增task的提交
    submitNew(index) {
      if (this.newForm[index].clue_name === "") {
        toMessage("error", this.$t("cluePanel.error3"));
        return;
      }
      this.loading = true;
      this.axios
        .post("/home/CrmContact/addClue", {
          clue_name: this.newForm[index].clue_name,
          contact_list: [
            {
              name: this.newForm[index].person,
              mobile: this.newForm[index].phone,
            },
          ],
          company: this.newForm[index].bus_name,
          email: this.newForm[index].email,
          clue_status: this.panelOption[index].id,
        })
        .then((res) => res.data)
        .then((res) => {
          this.loading = false;
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          this.getInfo();
          this.panelOption[index].clue_list.push(this.newForm[index]);
          this.panelOption[index].isClickAdd = false;
          this.newForm.splice(index, 1, {
            person: "",
            phone: "",
            bus_name: "",
            email: "",
            status: "-1",
            create_time: "",
          });
        });
    },
    // 指派任务弹窗多选框的全选
    handleCheckAllChange(val, index) {
      let allId = [];
      this.personOp[index].children.forEach((item) => {
        allId.push(item.value);
      });
      if (val) {
        this.checkValArr.splice(index, 1, allId);
      } else {
        this.checkValArr.splice(index, 1, []);
      }
      console.log(this.checkValArr[index]);
      this.isIndeterminateArr.splice(index, 1, false);
    },
    // 指派任务弹窗多选框的单选
    handleCheckedChange(value, index) {
      let checkedCount = value.length;
      let isCheckAllflag =
        checkedCount === this.personOp[index].children.length;
      this.checkAllArr.splice(index, 1, isCheckAllflag);
      let isIndeterminateflag =
        checkedCount > 0 && checkedCount < this.personOp[index].children.length;
      this.isIndeterminateArr.splice(index, 1, isIndeterminateflag);
    },
    emptyUser() {
      this.checkAllArr = [];
      this.checkValArr = [];
      this.isIndeterminateArr = [];
      this.personOp.forEach(() => {
        this.checkAllArr.push(false);
        this.isIndeterminateArr.push(false);
        this.checkValArr.push([]);
      });
    },
    // 批量指派
    addAssign() {
      this.emptyUser();
      let checkTask = [];
      this.panelOption.forEach((item) => {
        if (item.clue_list) {
          item.clue_list.forEach((ele) => {
            if (ele.isChecked) {
              checkTask.push(ele);
            }
          });
        }
      });
      if (checkTask.length == 0) {
        this.$message(toMessage("error", this.$t("cluePanel.error4")));
      } else {
        this.dialogVisible = true;
      }
      this.userDialogTit = this.$t("clueList.newAssign");
      this.isMultiple = true;
    },
    // 单个指派
    singleAddAssign(id, index, pIndex) {
      this.emptyUser();
      this.userDialogTit = this.$t("clueList.editLeader");
      this.singleId = id;
      this.isMultiple = false;
      this.dialogVisible = true;
      let charge_user_ids = this.panelOption[index].clue_list[pIndex]
        .charge_user_ids;
      let userArr = [];
      if (charge_user_ids.indexOf(",")) {
        userArr = charge_user_ids.split(",");
      } else {
        userArr = charge_user_ids * 1;
      }
      // 更改跟进人中checkbox 选择的样式
      this.personOp.forEach((item) => {
        item.childrenL = 0;
        item.children.forEach(() => {
          item.childrenL += 1;
        });
      });
      this.personOp.forEach((item, index) => {
        let checkL = 0;
        item.children.forEach((sItem) => {
          userArr.forEach((uItem) => {
            if (sItem.value == uItem) {
              checkL += 1;
              this.checkValArr[index].push(uItem * 1);
            }
          });
        });
        let isCheckAllflag = checkL === item.childrenL;
        this.checkAllArr.splice(index, 1, isCheckAllflag);
        let isIndeterminateflag = checkL > 0 && checkL < item.childrenL;
        this.isIndeterminateArr.splice(index, 1, isIndeterminateflag);
      });
    },
    // 获取信息
    getInfo() {
      this.loading = true;
      this.axios
        .post("/home/CrmContact/getPanelInfoList")
        .then((res) => res.data)
        .then((res) => {
          this.loading = false;
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          this.statusOptions = [];
          res.data.forEach((item) => {
            item.isClickAdd = false;
            this.statusOptions.push({
              name: item.name,
              id: item.id,
            });
            this.addNewForm();
            this.titIsEdit.push([false, false]);
          });
          res.data.forEach((element) => {
            if (element.clue_list) {
              element.clue_list.forEach((item) => {
                item.isChecked = false;
              });
            }
          });
          this.panelOption = res.data;
        });
    },
    addTab: function (
      option = "Index",
      action = "welCome",
      name = "首页",
      param
    ) {
      let url = "/" + option + "/" + action;
      if (param) {
        for (var i in param) {
          url += "/" + param[i];
        }
      }
      console.log(name);
      this.$router.push(url);
    },
    addTask(taskOp, panelIndex, pIndex) {
      if (taskOp.val == 4) {
        this.addTab("CrmTask", "taskInfo", "<tags:lang>创建任务</tags:lang>", {
          source_id: this.panelOption[panelIndex].clue_list[pIndex].id,
          source_type: 2,
          source_name: this.panelOption[panelIndex].clue_list[pIndex].clue_name,
        });
        return;
      }
      if (
        this.panelOption[panelIndex].clue_list[pIndex].contact_list == undefined
      ) {
        this.$message(toMessage("error", this.$t("cluePanel.error5")));
        return;
      }
      this.taskPerson = "";
      this.taskTime = "";
      this.taskContent = "";
      this.dialogType = taskOp.val;
      this.clue_id = this.panelOption[panelIndex].clue_list[pIndex].id;
      this.taskType = taskOp.val;
      this.addTaskDialogVisible = true;
      this.contactList = this.panelOption[panelIndex].clue_list[
        pIndex
      ].contact_list;
    },
    getUser() {
      this.axios
        .post("/home/CrmContact/getChargeUserList")
        .then((res) => res.data)
        .then((res) => {
          this.loading = false;
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          res.data.class_list = res.data.class_list.filter((item) => {
            return item.children !== undefined;
          });
          this.personOp = res.data.class_list;
          res.data.class_list.forEach((item) => {
            if (item.children) {
              this.checkAllArr.push(false);
              this.isIndeterminateArr.push(false);
              this.checkValArr.push([]);
            }
          });
        });
    },
    // 添加指派中的弹窗
    submitUser() {
      let ids = [];
      let charge_user_ids = [];
      if (this.isMultiple === false) {
        ids = [this.singleId];
      } else {
        this.panelOption.forEach((element) => {
          if (element.clue_list) {
            element.clue_list.forEach((item) => {
              if (item.isChecked) {
                ids.push(item.id);
              }
            });
          }
        });
      }
      this.checkValArr.forEach((fItem) => {
        fItem.forEach((sItem) => {
          charge_user_ids.push(sItem);
        });
      });
      if (this.isMultiple && charge_user_ids.length == 0) {
        this.$message(toMessage("error", this.$t("cluePanel.error6")));
        return;
      }
      this.$confirm(this.$t("cluePanel.text14"), this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          this.axios
            .post(
              this.isMultiple
                ? "/home/CrmContact/cluePlanOperation"
                : "/home/CrmContact/changeCharge",
              {
                ids: ids,
                charge_user_ids: charge_user_ids,
                source_type: this.isMultiple ? 1 : 0,
                operation: 1,
              }
            )
            .then((res) => res.data)
            .then((res) => {
              this.loading = false;
              if (res.status == 0) {
                this.$message(toMessage("error", res.msg));
                return;
              }
              if (res.status == 1001) {
                this.$message(
                  toMessage("warning", this.$t("cluePanel.warning"))
                );
              } else {
                this.$message(toMessage());
              }
              this.getInfo();
              this.dialogVisible = false;
              this.singleId = "";
            });
        })
        .catch(() => {});
    },
    transformCus(id) {
      this.addTab(
        "CrmContact",
        "contactInfo",
        "<tags:lang>客户</tags:lang>-<tags:lang>转换</tags:lang>",
        { source_id: id }
      );
    },
    // 提交任务
    submitTask() {
      if (this.taskPerson == "") {
        this.$message(toMessage("error", this.$t("cluePanel.error7")));
        return;
      }
      if (this.taskType == "") {
        this.$message(toMessage("error", this.$t("cluePanel.error8")));
        return;
      }
      if (this.taskTime == "") {
        this.$message(toMessage("error", this.$t("cluePanel.error9")));
        return;
      }
      this.loading = true;
      this.axios
        .post("/home/CrmContact/addPlan", {
          clue_id: this.clue_id,
          plan_type: this.taskType,
          contact_id: this.taskPerson,
          plan_desc: this.taskContent,
          want_create_time: this.taskTime,
        })
        .then((res) => res.data)
        .then((res) => {
          this.loading = false;
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          this.addTaskDialogVisible = false;
          this.getInfo();
        });
    },
    // 删除线索
    delClue(isSingle, id) {
      if (!isSingle) {
        // 批量放弃
        let idArr = [];
        this.panelOption.forEach((element) => {
          if (element.clue_list) {
            element.clue_list.forEach((item) => {
              if (item.isChecked) {
                idArr.push(item.id);
              }
            });
          }
        });
        if (idArr.length === 0) {
          this.$message(toMessage("error", this.$t("cluePanel.error10")));
          return;
        }
      }
      this.$confirm(this.$t("email.confirmToDelete"), this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          let ids = [];
          if (isSingle) {
            ids = [id];
          } else {
            // 批量删除
            this.panelOption.forEach((element) => {
              if (element.clue_list) {
                element.clue_list.forEach((item) => {
                  if (item.isChecked) {
                    ids.push(item.id);
                  }
                });
              }
            });
          }
          this.loading = true;
          this.axios
            .post("/home/CrmContact/cluePlanOperation", {
              ids: ids,
              operation: 3,
              source_type: isSingle ? 0 : 1,
            })
            .then((res) => res.data)
            .then((res) => {
              this.loading = false;
              if (res.status !== 1) {
                this.$message(toMessage("error", res.msg));
                return;
              }
              this.getInfo();
            });
        })
        .catch(() => {});
    },
    // 新增线索
    jumpClue() {
      this.addTab(
        "CrmContact",
        "clueDetails",
        "<tags:lang>新增线索</tags:lang>"
      );
    },
    // 编辑任务
    editClue(id) {
      this.addTab(
        "CrmContact",
        "clueDetails",
        "<tags:lang>编辑线索</tags:lang>",
        {
          id: id,
          is_view: 0,
        }
      );
    },
    hidePanel(index) {
      this.$set(this.panelOption[index], "isClickAdd", false);
    },
    jumpTask(id) {
      this.addTab("CrmTask", "taskInfo", "<tags:lang>任务详情</tags:lang>", {
        id: id,
        is_view: 1,
      });
    },
    // 拖拽线索
    draggableClue(e) {
      // 过滤同一个看板
      if (e.item.dataset.status_id == e.to.dataset.status_id) {
        return;
      }
      this.axios
        .post("/home/CrmContact/savePanelDrag", {
          clue_id: e.clone.dataset.id,
          status_id: e.to.dataset.status_id,
        })
        .then((res) => res.data)
        .then((res) => {
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            this.getInfo();
            return;
          }
          this.$message(toMessage());
        });
    },
    // 拖拽头部
    draggableHead(e) {
      if (e.to.className !== "draggable-main-panel") {
        return false;
      }
    },
    // 跳转详情页
    jumpDetail(id) {
      this.addTab(
        "CrmContact",
        "clueDetails",
        "<tags:lang>线索详情</tags:lang>",
        {
          id: id,
          is_view: 1,
        }
      );
    },
  },
  created: function () {
    this.getInfo();
    this.getUser();
    // $(window).scroll(function () {
    //   var scrollTop =
    //     document.documentElement.scrollTop || document.body.scrollTop;
    //   if (scrollTop > 50) {
    //     $(".panel-item").addClass("shadow");
    //   } else {
    //     $(".panel-item").removeClass("shadow");
    //   }
    // });
  },
};
</script>

<style scoped>
#clue-panel {
  padding: 7px !important;
  height: 100%;
  overflow: auto;
  background: #eaeaea;
}
.panel-box {
  padding: 30px;
  margin-bottom: 6px;
  width: 100%;
  height: 88px;
  background: #fff;
  position: sticky;
  top: 7px;
  z-index: 9;
}
.inner-panel {
  /* position: fixed;
  top: 38+28+50px;
  right: 20px; */
}
.inner-panel >>> .el-button {
  font-size: 14px;
}
.panel-box::after {
  position: absolute;
  content: " ";
  left: 0;
  top: -9px;
  width: 100%;
  height: 9px;
  background: #eaeaea;
}
.panel-box::before {
  position: absolute;
  content: " ";
  left: 0;
  bottom: -9px;
  width: 100%;
  height: 9px;
  background: #eaeaea;
}
.panel-box h1 {
  font-size: 24px;
  color: #000;
}
.bg_white {
  background: #fff;
}
.white-btn {
  border: none;
}
.white-btn:hover,
.white-btn:focus {
  color: rgb(96, 98, 102);
  background-color: #fff;
  border-color: #fff;
}
.mr40 {
  margin-right: 40px;
}
.panel-index {
  font-size: 44px;
  color: #0a84ff;
  margin-right: 5px;
}
.panel-name {
  font-size: 16px;
  color: #1c1c1e;
  font-weight: 600;
}
.panel-desc {
  font-size: 12px;
  color: #1c1c1e;
}
.main-panel {
  display: flex;
  justify-content: space-between;
  padding-bottom: 220px;
  margin: 0 auto;
}
.panel-w {
  /* flex: 1; */
  width: 560px;
  margin-right: 9px;
}
.panel-w:last-child {
  margin-right: 0;
}
.panel-item {
  height: 76px;
  padding: 15px;
  position: sticky;
  top: 101px;
  z-index: 9;
}
.move {
  cursor: move;
}
.shadow {
  box-shadow: 0 7px 10px 0px rgba(0, 0, 0, 0.1);
}
.panel-item-box {
  padding: 16px 24px 16px 34px;
  margin-top: 5px;
  cursor: move;
  position: relative;
  height: 370px;
}
.panel-item-box:hover .popover-option {
  display: flex;
}
.name-time {
  margin-bottom: 24px;
}
.name-time h1 {
  font-size: 16px;
}
.name-time .time {
  color: #9b9b9b;
  font-size: 14px;
}
.panel-form_item {
  font-size: 16px;
  margin-bottom: 15px;
  color: #333333;
}
.panel-form_item:last-child {
  margin-bottom: 0;
}
.panel-form_tit {
  color: #858585;
}
.mt30 {
  margin-top: 30px;
}
.new-form_item {
  margin-bottom: 20px;
}
.new-form_item .panel-form_tit {
  width: 100px;
  text-align: right;
}
.w268 {
  width: 268px;
}
.add-new-box {
  margin-top: 5px;
  padding: 18px 24px 25px;
  position: relative;
}
.add-new-box:hover .close-icon {
  display: inline-block;
}
.red {
  margin-right: 3px;
}
.mr40 {
  margin-right: 40px;
}
.handel-box {
  height: 80px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 30px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
}
.edit-panel-icon {
  font-size: 24px;
  /* position: absolute;
  right: 26px;
  top: 28px; */
  color: #ff7315;
  cursor: pointer;
}
.draggable-item {
  min-height: 300px;
  height: 100%;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.icon-box {
  /* position: absolute;
  right: 17px;
  top: 30px; */
}
.delete-icon {
  font-size: 20px;
  cursor: pointer;
  color: #0a84ff;
}
.con {
  margin: 0 auto;
  padding-bottom: 150px;
  min-width: 100%;
}
.popover-option {
  display: none;
  justify-content: space-around;
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 560px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
}
.popover-option::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 560px;
  height: 4px;
  background: #eaeaea;
}
.popover-option::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #fff transparent;
  z-index: 2;
}
.popover-option .popover-item {
  line-height: 50px;
  height: 50px;
  display: inline-block;
  color: #333333;
  font-size: 14px;
  cursor: pointer;
}
.singleStatus-ul {
  flex-direction: column;
  padding: 15px;
  display: none;
  position: absolute;
  top: 50px;
  left: 107%;
  min-width: 137px;
  transform: translateX(-50%);
  z-index: 3;
  box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.1);
}
.singleStatus-ul::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 137px;
  height: 4px;
  background: #eaeaea;
}
.singleStatus-ul::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #fff transparent;
  z-index: 2;
}
.singleStatus-ul li {
  line-height: 1.2;
  font-size: 14px;
  color: #333333;
  margin-bottom: 12px;
}
.singleStatus-span {
  position: relative;
}
.singleStatus-span:hover .singleStatus-ul {
  display: flex;
}
.yellow-text {
  color: #ff7315;
}
.add-btn {
  position: absolute;
  right: 24px;
  bottom: 20px;
}
.task-ul {
  padding-left: 12px;
}
.task-ul li {
  line-height: 30px;
  cursor: pointer;
}
.task-ul li:hover {
  color: #ff7315;
}
.group_name {
  color: #1c1c1e;
  font-weight: 600;
  font-size: 16px;
}
.w500 {
  width: 500px;
}
.tit {
  width: 100px;
  display: inline-block;
  text-align: right;
}
.w150 {
  width: 150px;
}
.close-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  display: none;
  font-size: 18px;
  color: #f56c6c;
}
.float-right-box {
  position: absolute;
  right: 20px;
  top: 63px;
}
.dialog-task_box .tit {
  width: 120px;
}
.w200 {
  width: 200px !important;
}
.task-icon {
  font-size: 18px;
}
.disabled-edit {
  pointer-events: none;
}
.draggable-main-panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.add-task-icon {
  color: #ff575c;
  font-size: 24px;
  /* position: fixed;
  top: 40px;
  right: 40px; */
}
.plan-detail {
  color: #333333;
  opacity: 0.6;
  height: 106px;
}
.plan-item {
  margin-bottom: 16px;
  font-size: 16px;
  margin-top: 29px;
  height: 16px;
  overflow: hidden;
}
.plan-item:last-child {
  margin-bottom: 0;
}
.user-photo {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 4px;
}
.user-box {
  width: 310px;
  overflow: hidden;
  display: flex;
}
.user-name {
  color: #1c1c1e;
  font-size: 14px;
}
.no-user {
  color: #1c1c1e;
  font-size: 16px;
}
.user-box span:last-child {
  margin-right: 0;
}
.user-dialog {
  padding-left: 32px;
  padding-right: 32px;
}
.department-tit {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: baseline;
  position: relative;
  margin-bottom: 16px;
}
.department-tit >>> .el-checkbox__label {
  padding-left: 0;
  margin-right: 6px;
}
.mr80 {
  margin-right: 80px;
}
.clue-name_hand {
  font-weight: 600;
  color: #ff7315;
}
.plan_desc {
  max-width: 270px;
  display: inline-block;
}
</style>